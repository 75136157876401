import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FilterService } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AppTitleService } from '@core/services/app-title.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
@UntilDestroy()
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: AppTitleService,
    private readonly filterService: FilterService,
    private readonly primengConfig: PrimeNGConfig,
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => ({ route: this.activatedRoute, event })),
        filter(({ route }) => route.outlet === 'primary'),
        map((value) => {
          while (value.route.firstChild) {
            value.route = value.route.firstChild;
          }
          while (!value.route.snapshot.data['title'] && value.route.parent) {
            value.route = value.route.parent;
          }
          return value;
        }),
        mergeMap(({ route, event }) =>
          combineLatest([route.data, route.queryParams]).pipe(
            take(1),
            map((results) => ({
              data: results[0],
              queryParams: results[1],
              event,
            })),
          ),
        ),
      ),
    ]).subscribe(([route]) => {
      if (route.data.title) {
        let title = '';
        if (route.data.title instanceof Function) {
          title = route.data.title(route.data);
        } else {
          title = route.data.title;
        }

        if (title) {
          this.titleService.set({
            title,
            prefix: route.data.titleSuffix,
            browserTitle: route.data.browserTitle,
          });
        }
      }
    });

    if (environment.plausible) {
      this.createPlausibleScript(environment.plausible);
    }

    this.createPrimeTableFilter();
  }

  createPlausibleScript(domain: string) {
    const script = document.createElement('script');
    script.setAttribute('data-domain', domain);
    script.defer = true;
    script.src = 'https://plausible.io/js/plausible.js';
    const body = document.getElementsByTagName('body')[0];
    body.appendChild(script);
  }

  createPrimeTableFilter() {
    this.filterService.register(
      'array-filter',
      (value: any[] | undefined, filter: string[] | undefined): boolean => {
        if (!filter || !filter.length) {
          return true;
        }
        if (!value) {
          return false;
        }
        return filter.some(
          (f) =>
            value.some((v) => v === f) ||
            value.some((v) => v.name === f) ||
            (!f && !value.length),
        );
      },
    );
  }

  ngOnDestroy(): void {}
}
